<template>
  <div class="reserve">
    <h3 class="title">
      Please select the user you want to deposit a package to
    </h3>

    <div class="form-error" v-if="error.length > 0">
      <span>{{ error }}</span>
    </div>

    <form action="#" @submit="handleInput">
      <div class="input-control">
        <!-- Select with dynamic options -->
        <select v-model="selectedUser">
          <option value="0" disabled selected>Select your option</option>
          <option v-for="user in users" :key="user.id" :value="user.id">
            {{ user.name }} {{ user.surname }}
          </option>
        </select>
        <button type="submit">Go</button>
      </div>
    </form>
    <SimpleKeyboard @onChange="onChange" :input="barcode" />

    <div class="modal-backdrop" v-if="modalShow">
      <div class="modal">
        {{ modalText }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.reserve {
  width: 85%;
}
.reserve .simple-keyboard {
  transform: scaleY(1.4) translateY(10%);
}

h3.title {
  font-size: 42px;
  padding: 30px;
  font-family: "Anton";
  background-color: #ebebeb;
  color: #222;
  margin-bottom: 30px;
}

.form-error {
  font-size: 42px;
  padding: 30px;
  font-family: "Anton";
  background-color: rgba(red, 0.5);
  color: #222;
  margin-bottom: 30px;
}

.input-control {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

input,
select {
  width: 90%;
  padding: 20px;
  border: 3px solid #ddd;
  border-radius: 0;
  font-size: 36px;
  flex-basis: 90%;
  height: 100px;
}

input:focus,
select:focus {
  outline: none;
  border: 3px dashed blue;
  border-radius: 10px 0 0 10px;
}

button {
  background-color: #ddd;
  border: 3px solid #ddd;
  color: #222;
  width: 100%;
  height: 100px;
  flex-basis: 15%;
  cursor: pointer;
  font-size: 28px;
  font-weight: bold;
}

form {
  margin-bottom: 30px;
}

.modal-backdrop {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px);
  z-index: 1000;
}

.modal {
  background-color: #333;
  color: #fff;
  border: 1px solid #222;
  padding: 20px;
  backdrop-filter: blur(10px);
  border-radius: 10px;
  font-size: 32px;
}
</style>

<script>
import service from "@/api/service";
import lockerConfig from "../lockerConfig";

export default {
  name: "Leave A Package",
  components: {},
  data() {
    return {
      error: "",
      modalShow: false,
      modalText: "",

      selectedUser: 0,
      users: [],
    };
  },
  mounted() {
    service.allUsers().then((response) => {
      let gotUsers = response;
      this.users = [];
      gotUsers.forEach((user) => {
        if (user.is_public == 0) return;

        this.users.push({
          id: user.id,
          name: user.name,
          surname: user.surname,
        });
      });

      console.log(response, this.users);
    });
  },
  methods: {
    handleInput(e) {
      e.preventDefault();
      if (this.selectedUser === 0) {
        this.error = "Please select a user";
        return;
      }

      let users = this.users.slice();

      let foundUser = 0;
      for (let i = 0; i < users.length; i++) {
        if (users[i].id == this.selectedUser) {
          foundUser = users[i];
          break;
        }
      }

      if (foundUser == 0) {
        this.error = "User not found";
        return;
      }

      this.$router.push({
        name: "QuickDeposit/SelectSize",
        query: {
          userId: this.selectedUser,
        },
      });
    },
  },
};
</script>
